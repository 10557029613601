@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.modalWrap {
    width: 600px;
    background-position: 50% 71px;
    background-repeat: no-repeat;

    &.success {
        background-size: 107px auto;
        background-image: url('../../../assets/icons/orange_done.svg');
    }

    &.error {
        background-size: 33px auto;
        background-image: url('../../../assets/icons/orange_sad.svg');
    }
}

.title {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: $black;
    margin: 188px auto 74px;
}

@include mobile-only {
    .modalWrap {
        width: 80%;
    }
}