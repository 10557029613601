@import '../../styles/variables.scss';

.inputElWrap {
    position: relative;
}

.inputEl {
    width: 100%;
    padding: 0 0 0 20px;
    height: 35px;
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;
    color: $inputColor;
    border: 0;
    border-bottom: 1px solid $inputBorderColor;
    box-shadow: none;
    outline: 0;

    &:active,
    &:focus {
        box-shadow: none;
        outline: 0;
    }

    &.focused {
        border-color: $inputFocusedBorderColor;
    }

    &.withError {
        color: $inputErrorColor;
        border-bottom: 1px solid $inputErrorBorderColor;
    }
}

.inputPlaceholder {
    position: absolute;
    left: 20px;
    bottom: 9px;
    font-size: 17px;
    line-height: 21px;
    font-weight: normal;
    color: $inputPlaceholderColor;
}


.inputPlaceholderRequired {
    color: $inputErrorColor;
}

.errorEl {
    position: absolute;
    left: 20px;
    bottom: 27px;
    font-size: 13px;
    line-height: 17px;
    color: $inputErrorColor;
}
