@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.overviewBlock {
  margin-top: -40px;
}


.faqBlock {
  background-color: $faqBackground;
}

.featuresBlock {
  margin-bottom: -40px;

  @include mobile-only {
    margin-bottom: -20px;
  }
}

.links {
  @include flex (center, center);

  a {
    margin: 0 5px;
  }


  @include up-to-tablet {
    flex-direction: column;

    a {
      margin-bottom: 10px;
    }
  }
}

.description {
  margin: 0 auto;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 30px;

  p {
    margin-bottom: 15px;
  }
}

.familiarCases {
  padding-bottom: 10px;

  h2 {
    text-align: center;
    margin-bottom: 25px;

    @include mobile-only {
      text-align: left;
    }

    span {
      color: $orange;
    }
  }
}
