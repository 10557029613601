@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.titleEl {
    margin-bottom: 30px;
    text-align: center;
}

.questionBlock {
    margin-bottom: 30px;
}

.questionBlock:last-child {
    margin-bottom: 0;
}

.questionEl {
    position: relative;
    margin-bottom: 12px;
    padding: 0 40px 0 0;
    color: $orange;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    cursor: pointer;
}

.expandIcon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 17px;
    height: 10px;
    margin-top: -8px;
    background-color: transparent;
    background-image: url('../../assets/icons/arrow.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 17px 10px;
    transform: rotate(180deg);

    .questionBlockExpanded & {
        transform: rotate(0deg);
    }
}

.answerEl {
    display: none;
    font-weight: 300;
    font-size: 19px;
    line-height: 28px;

    .questionBlockExpanded & {
        display: block;
    }
}

@include mobile-only {
    .titleEl {
        text-align: left;
    }
}
