@import '~video-react/styles/scss/video-react';
@import '../../styles/variables.scss';

.video-react {
    max-width: 800px;
    margin-bottom: 40px;
    border-radius: 10px;
    overflow: hidden;
}

.video-react .video-react-poster {
    background-size: 120%;
}
