@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.listWrap {
    @include flex(center, center);
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.technologyWrap {
    display: inline-block;
    margin: 0 13px 10px 13px;
}

.technologyImg {
    height: 30px;
    width: auto;
}