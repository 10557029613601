@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.titleEl {
    margin-right: 27px;
}

.btnCta {
    font-size: 20px;
    line-height: 25px;
}

@include mobile-only {
    .wrap {
        flex-direction: column;
    }

    .titleEl {
        margin-right: 0;
        margin-bottom: 20px;
    }
}