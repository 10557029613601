@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.card {
  display: block;
  width: 380px;
  box-shadow: 0px 0.716948px 1.4339px rgba(0, 0, 0, 0.035),
    0px 5.72571px 11.4514px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-bottom: 40px;
  overflow: hidden;
  transition-duration: 0.35s;

  &:hover {
    box-shadow: 0 20px 30px -11px rgba(31, 35, 65, 0.2);
    transform: translateY(-5px);
  }



  @include up-to-desktop() {
    width: 100%;
  };

  &:nth-child(3n) {
    margin-right: 0;
  }

  figure {
    min-height: 167px;
    background-color: #eee;

    @include mobile-only() {
      min-height: 120px;
    }
  }
}

.textWrap {
  padding: 16px 24px;
}

.title {
  font-family: 'Fira Sans';
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.content {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 10px;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;

  li {
    font-size: 12px;
    line-height: 1;
    background-color: #f2f2f2;
    font-weight: 400;
    padding: 4px 8px;
    margin-bottom: 8px;
    border-radius: 8px;
  }

  li + li {
    margin-left: 8px;
  }
}

.link {
  color: $orange;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;

  span {
    position: relative;
    top: 1px;
  }
}
