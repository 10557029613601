@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.wrap {
    display: flex;
}

.titlePart {
    width: 50%;
}

.titleEl {
    margin-bottom: 30px;
}

.filesListPart {
    width: 50%;
}

.fileWrap {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &:last-child {
        margin-bottom: 0;
    }
}

.fileIcon {
    display: block;
    width: 25px;
    height: 25px;
    min-width: 25px;
    margin: 0 15px 0 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 0 50%;

    .presentationType & {
        background-image: url('../../assets/icons/presentation.svg');
    }

    .newspaperType & {
        background-image: url('../../assets/icons/newspaper.svg');
    }
}

.fileLink {
    display: block;
    font-size: 19px;
    line-height: 30px;
    text-decoration: underline;
    color: #161616;

    &:hover  {
        text-decoration: underline;
        color: #161616;
    }
}

@include mobile-only {
    .wrap {
        flex-direction: column;
    }

    .titleEl {
        margin-bottom: 20px;
    }

    .titlePart,
    .filesListPart {
        width: auto;
    }
}
