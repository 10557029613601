@mixin up-to-desktop {
  @media (max-width: 1239px) {
    @content;
  }
}

@mixin up-to-tablet {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin flex($justify: flex-start, $align: flex-start, $direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin overlay() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
