@import '../../styles/variables.scss';

.wrap {
  padding: 30px 0;
}

.intro {
  font-size: 19px;
  line-height: 28px;
  margin-bottom: 24px;
}
