@import "../../styles/variables";
@import "../../styles/mixins";

.tagsMenu {
  height: 70px;
  position: relative;

  nav {
    background-color: $navBgColor;
  }

  ul {
    display: flex;
  }

  li + li {
    margin-left: 20px;
  }

  a {
    display: block;
    height: 70px;
    font-weight: 600;
    font-size: 19px;
    line-height: 70px;
    position: relative;
    transition: background-color 0.1s;

    &:hover {
      background-color: #ececec;
    }

    &.selected {
      color: $orange;
      cursor: default;

      &:hover {
        background-color: transparent;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: $orange;
        bottom: 0;
      }
    }
  }

  @include up-to-desktop() {
    height: auto;

    .container {
      padding: 0;
    }

    ul {
      flex-direction: column;
    }

    li + li {
      margin-left: 0;
    }

    a {
      height: 48px;
      line-height: 48px;
      font-size: 17px;
      font-weight: 600;
      padding-left: 20px;
      display: none;

      &.selected {
        display: block;
        color: $default;
        &:after {
          display: none;
        }
      }
    }

    .is-open {
      a {
        display: block;

        &.selected {
          color: $orange;
        }
      }
    }
  }
}

.toggleTagsMenu {
  display: none;
  position: absolute;
  z-index: 2;
  width: 32px;
  height: 32px;
  top: 8px;
  right: 6px;

  &:after {
    margin: 2px 0 0 4px;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }

  &.active {
    &:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #000;
      border-top: none;
    }
  }

  @include up-to-desktop() {
    display: block;
  }
}

@include up-to-desktop() {
  .tagsMenuMobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $navBgColor;
    z-index: 3;
  }
}
