@import "../../styles//variables.scss";
@import "../../styles//mixins.scss";

.titleEl {
  margin-bottom: 60px;
  text-align: center;
}

.featuresWrap {
  display: flex;
  flex-wrap: wrap;
}

.featureWrap {
  @include flex(center, center, column);
  width: 50%;
  padding: 0 10px 40px 10px;
}

.featureImg {
  width: 90px;
  height: 90px;
  background-color: rgba(255, 133, 0, 0.4);
  border-radius: 50%;
  margin-bottom: 30px;
  position: relative;
  @include flex(flex-end, center);

  img {
    position: absolute;
    max-width: 45px;
    right: -2px;
  }
}

.featureTitle {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
}

.featureText {
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

@include mobile-only {
  .titleEl {
      margin-bottom: 44px;
      text-align: left;
  }

  .featuresWrap {
      flex-direction: column;
  }

  .featureWrap {
    width: 100%;
  }
}
