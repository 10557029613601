//colors
$black: #000;
$white: #fff;
$gray: #4a4a4a;
$orange: #FF8500;
$default: $black;

$light-green: #C4C601;
$green: #A9AA00;
$dark-green: #949204;

$light-red: #FFA7A7;
$red: #FD1C1C;

$desktop-width: 1080px;
$header-height: 70px;

$breadcrumbsColor: #999;
$navBgColor: #fafafa;
$footerBgColorMobile: #f0f0f0;

$btnBgBasic: linear-gradient(74deg,#fe6b59 3%,#fba320 69%,#fe8d31 100%);
$btnBgHoverBasic: linear-gradient(74deg,#fe6b59 3%,#fba320 69%,#fe8d31 100%);
$btnShadowHoverBasic: 0 8px 12px 0 rgba(255,133,22,.75);

$btnBgPressedBasic: transparent;
$btnBorderColorPressedBasic: #C9C9C9;

$btnColorDisabledBasic: #C8C5C5;
$btnBgDisabledBasic: transparent;
$btnBorderColorDisabledBasic: rgba(151, 151, 151, 0.47);

$checkBoxBg: #FF8500;
$checkBoxBorderColor: #9B9B9B;
$checkboxTitleColor: #4A4A4A;

$inputColor: #4A4A4A;
$inputBorderColor: rgba(0, 0, 0, 0.25);
$inputFocusedBorderColor: #000000;

$inputPlaceholderColor: #B3B3B3;

$inputErrorColor: #EB5757;
$inputErrorBorderColor: #EA5B25;

$faqBackground: #f8f8f8;

//measures
$desktopWidth: 1000px;

//media queries
$iPhone11-width: 414px;