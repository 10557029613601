@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.bottom {
  height: 68px;
  font-size: 15px;
  @include flex(space-between, center);
  @include up-to-desktop() {
    height: 88px;
    font-size: 12px;
    flex-direction: column;
    padding: 10px 0;
  }

  a {
    svg {
      opacity: 0.4;
      transition: .1s;
    }

    &:hover svg {
      opacity: 1;
    }
  }

  @include up-to-tablet() {
    border-top: none;
    font-size: 15px;
    padding-top: 30px;

    ul + ul {
      display: none;
    }

    ul {
      li {
        display: none;
      }

      li:first-child {
        display: block;
      }
    }
  }

  ul {
    display: flex;

    li + li {
      margin-left: 12px;
    }

    a {
      color: rgba(0,0,0, 0.4);

      &:hover {
        color: #000;

        img {
          filter: brightness(0);
        }
      }
    }
  }

  ul + ul {
    li:first-child {
      margin-right: 30px;
    }
  }
}

