@import '../../styles/variables.scss';

.checkboxContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 3px 0;
    cursor: pointer;
}

.checkbox {
    display: block;
    width: 16px;
    height: 16px;
    min-width: 16px;
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid $checkBoxBorderColor;
    box-sizing: border-box;
}

.checkboxChecked {
    background-color: $checkBoxBg;
    background-image: url('../../assets/icons/checkbox.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: none;
}

.checkboxTitle {
    margin: 0 0 0 9px;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $checkboxTitleColor;

    & a {
        font-size: 13px;
        line-height: 16px;
        color: $checkboxTitleColor;
        text-decoration: underline;
    }

    & a:hover,
    & a:active {
        color: $checkboxTitleColor;
        text-decoration: underline;
    }
}
