@import '../../styles//variables.scss';
@import '../../styles//mixins.scss';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;

  @include up-to-tablet() {
    margin-top: 20px;
    margin-left: 10px;
  }

  li {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    a {
      color: $breadcrumbsColor;

      &:after {
        content: '>';
        padding-left: 5px;
        color: $breadcrumbsColor;
      }

      &:hover {
        color: $orange;
      }
    }
  }

  li + li {
    margin-left: 5px;
  }
}
