@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unna:ital@1&display=swap');
@import 'variables';
@import 'normalize';
@import 'mixins';

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: $default;
  background-color: #fff;
}

input, button {
  font-family: 'Source Sans Pro', sans-serif;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

header {
  height: 80px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  position: relative;
  z-index: 3;
}

footer {
  color: rgba(0,0,0, 0.4);
  border-top: 1px solid rgba(0,0,0, 0.2);

  @include up-to-tablet() {
    background-color: $footerBgColorMobile;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

h1 {
  font-family: 'Fira Sans';
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  margin-bottom: 25px;

  @include up-to-tablet() {
    font-size: 32px;
    line-height: 38px;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Overlay {
  z-index: 100;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 40px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  line-height: 16px;
  box-shadow: none;
  outline: none;
  border: none;
  border-radius: 20px;
  transition: all 500ms ease;

  cursor: pointer;

  &[disabled] {
    cursor: default;
  }

  &.basic {
    color: $white;
    background: $btnBgBasic;

    &:not([disabled]):not(.pressed):hover {
      background: $btnBgHoverBasic;
      box-shadow: $btnShadowHoverBasic;
      transform: translateY(-5px);
    }

    &:not([disabled]).pressed {
      color: $black;
      background: $btnBgPressedBasic;
      border: 1px solid $btnBorderColorPressedBasic;
      box-shadow: none;
    }

    &[disabled] {
      color: $btnColorDisabledBasic;
      background: $btnBgDisabledBasic;
      border: 1px solid $btnBorderColorDisabledBasic;
      box-shadow: none;
    }
  }

  &.cta {
    color: $white;
    background: $btnBgBasic;
    font-size: 20px;
    line-height: 25px;
    padding: 0 60px;
    height: 60px;
    border-radius: 50px;

    @include mobile-only() {
      font-size: 16px;
    }

    &:not([disabled]):not(.pressed):hover {
      background: $btnBgHoverBasic;
      box-shadow: $btnShadowHoverBasic;
      transform: translateY(-5px);
    }

    &[disabled] {
      color: $btnColorDisabledBasic;
      background: $btnBgDisabledBasic;
      border: 1px solid $btnBorderColorDisabledBasic;
      box-shadow: none;
    }
  }
}

.demoDetailsTitle {
  font-family: 'Fira Sans';
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}



@include mobile-only {
  .demoDetailsTitle {
      font-size: 32px;
      line-height: 38px;
  }
}

div[data-type="overview"] + div[data-type="explore"] {
  display: none;
}