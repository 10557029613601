@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.page {
    position: relative;
    background-color: $white;
}

.bgImg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 463px;
    z-index: 1;
}

.pageInner {
    position: relative;
    margin: 0 auto;
    max-width: 1200px;
    z-index: 2;
}

.formWrap {
    padding: 180px 0 112px 45%;
}

@include mobile-only {
    .page {
        background-size: 300px auto;
    }

    .formWrap {
        padding: 85px 20px 435px;
    }

    .bgImg {
        width: 302px;
    }
}