@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.box {
  @include flex(space-between, center);
  height: 80px;
}

.logo {
  @include flex(flex-start, center);
}

.company {
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  padding-left: 16px;
}

.menuBtn {
  display: none;
}

@include up-to-tablet() {
  .menu {
    display: none;
  }

  .logo {
    img {
      width: 109px;
    }
  }

  .company {
    height: 26px;
    line-height: 26px;
    font-size: 11px;
    margin-left: 11px;
    padding-left: 11px;
  }

  .menuBtn {
    display: block;
    width: 36px;
    height: 26px;
    border-top: 2px solid #000;
    position: absolute;
    right: 20px;
    top: 25px;
  
    &:before, &:after {
      position: absolute;
      display: block;
      content: '';
      height: 0;
      width: 100%;
      left: 0;
      border-top: 2px solid #000;
      transition: linear 0.2s;
    }
  
    &:before {
      top: 10px;
    }
  
    &:after {
      top: 24px;
    }
  }

  .menuBtnActive {
    border-top: none;

    &:before {
      transform: rotate(45deg);
      top: 11px;
    }

    &:after {
      transform: rotate(-45deg);
      top: 12px;
    }
  }

  .boxMobile {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    padding: 80px 20px 20px;
  
    .logo {
      display: none;
    }
  
    .menu {
      display: block;
      max-width: 250px;
      margin: 0 auto;
    }
  }
}