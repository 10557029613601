@import '../../styles/variables.scss';

.content {
    position: absolute;
    top : 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    padding: 0;
    margin-right: -50%;
    border: none;
    overflow: auto;
    outline: none;
    transform: translate(-50%, -50%);
    z-index: 1;
    background: $white;
    box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.xBtn {
    position: absolute;
    top : 0;
    right: 0;
    padding: 20px;
    width: 16px;
    height: 16px;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-image: url('../../assets/icons/x.svg');
    cursor: pointer;
}
