@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.wrap {
    max-width: 465px;
    width: 80%;
}

.formWrap {
    display: flex;
    flex-direction: column;
}

.nameInputsContainer {
    display: flex;

    .inputWrap {
        width: 230px;
    }

    .inputWrap:first-child {
        margin-right: 5px;
    }
}

.inputWrap {
    margin: 0 0 20px 0;
}

.checkboxes {
    margin: 42px 0 0 0;
}

.checkboxWrap {
    margin: 6px 0;
}

.contactButtonWrap {
    display: flex;
    justify-content: center;
    margin: 35px 0 0 0;
}

.title {
    margin-bottom: 32px;
    text-align: center;
    font-family: 'Fira Sans';
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: $black;
}

@include mobile-only {
    .wrap {
        width: auto;
        max-width: none;
    }

    .nameInputsContainer {
        flex-direction: column;

        .inputWrap {
            width: auto;
        }
    }
}
