@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.masonryGrid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.masonryColumn {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}