@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.titleEl {
    margin-bottom: 40px;
    text-align: center;
}

.testimonialsWrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -40px;
}

.testimonialWrap {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    margin-bottom: 40px;
    width: 50%;
}

.testimonialImg {
    margin-bottom: 28px;
    width: 90px;
    height: 90px;
}

.testimonialText {
    margin-bottom: 15px;
    font-family: 'Unna';
    font-style: italic;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #4F4F4F;
}

.testimonialAbout {
    font-size: 18px;
    line-height: 40px;
}

.testimonialName {
    font-weight: bold;
    color: #161616;
}

.testimonialPosition {
    color: #4F4F4F;
}

@include mobile-only {
    .titleEl {
        margin-bottom: 32px;
        text-align: left;
    }

    .testimonialsWrap {
        flex-direction: column;
        margin-bottom: 0;
    }

    .testimonialWrap {
        width: 100%;
        padding-right: 0;
    }
}
