@import "variables";

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

a {
  color: $default;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
  outline: none;
  border-width: 0;
  text-decoration: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:disabled {
  cursor: default;
}

img {
  max-width: 100%;
  vertical-align: top;
}
