@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.wrap {
    position: relative;

    h1 {
        text-align: center;
    }

    .dividerContainerEl {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .dividerEl {
        margin: 0 auto;
        height: 1px;
        max-width: 1218px;
        background-color: rgba(0, 0, 0, 0.2);
    }

    &:first-child {
        .dividerContainerEl {
            display: none;
        }
    }
}

.inner {
    margin: 0 auto;
    padding: 70px;
    max-width: 810px;
    box-sizing: content-box;
}

@include mobile-only {
    .wrap h1 {
        text-align: left;
    }

    .inner {
        padding: 40px 20px;
    }
}
